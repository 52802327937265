import firebase from "firebase"
import { navigate } from "gatsby"

import { isBrowser } from "services/general"

export const clearFailedLoginCounter = (email) => {
  if (isBrowser()) {
    localStorage.removeItem(btoa(email))
  }
}

export const handleVerifyPasswordResetCode = ({ actionCode }) => {
  firebase
    .auth()
    .verifyPasswordResetCode(actionCode)
    .then((response) => {
      clearFailedLoginCounter(response?.email)
    })
    .catch((error) => {
      navigate("/auth/error", {
        state: {
          seoTitle: "Invalid Code",
          title: "Request for a new reset link",
          message:
            "Your request to reset your password has expired or the link has already been used.",
        },
      })
    })
}

export const handleResetPassword = ({
  actionCode,
  successUrl,
  errorCallback,
  newPassword,
}) => {
  firebase
    .auth()
    .confirmPasswordReset(actionCode, newPassword)
    .then((response) => {
      // clearFailedLoginCounter(response?.email)
      navigate("/auth/success", {
        state: {
          seoTitle: "Password Reset Successful",
          title: "Your password has been reset",
          message: "You can now sign in with your new password.",
          successUrl,
        },
      })
    })
    .catch((error) => {
      if (errorCallback) errorCallback(error)
    })
}

export const handleVerifyEmail = ({ actionCode, successUrl }) => {
  firebase
    .auth()
    .applyActionCode(actionCode)
    .then((response) => {
      // clearFailedLoginCounter(response?.email)
      navigate("/auth/success", {
        state: {
          seoTitle: "Email Verification Successful",
          title: "Your email has been verified",
          message: "You can now sign in with your new account.",
          successUrl,
        },
      })
    })
    .catch((error) => {
      navigate("/auth/error", {
        state: {
          seoTitle: "Invalid Code",
          title: "Request for a new reset link",
          message:
            "Your request to verify your email has expired or the link has already been used.",
        },
      })
    })
}
