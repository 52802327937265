import * as Yup from "yup"

const REQUIRED_MESSAGE = "This field is required."

export const initialValues = { newPassword: "", confirmNewPassword: "" }

export const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required(REQUIRED_MESSAGE)
    .min(7, "Your password must be at least seven characters long.")
    .max(80, "Your password must not exceed 80 characters in length.")
    .matches(
      /(?=.*[a-z])/,
      "Your password must contain at least one lowercase character."
    )
    .matches(
      /(?=.*[A-Z])/,
      "Your password must contain at least one uppercase character."
    )
    .matches(/(?=.*[0-9])/, "Your password must contain at least one number.")
    .matches(
      /(?=.*[!|@|#|$|%|&|_|+|=|.|-])/,
      "Your password must contain at least one of these special characters: !@#$%&_-+=."
    )
    .matches(
      /^[a-zA-Z0-9!@#$%&_+=.-]{7,}$/,
      "Your password contains an invalid special character. Please use any from the following only: !@#$%&_-+=."
    ),
  confirmNewPassword: Yup.string()
    .required(REQUIRED_MESSAGE)
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match."),
})
